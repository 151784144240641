.DraftEditor-root {
  width: 100%;
  height: 100%;
}

.public-DraftStyleDefault-pre {
  background-color: rgba(0, 0, 0, 0.05);
  font-family: 'Inconsolata', 'Menlo', 'Consolas', monospace;
  font-size: 16px;
  padding: 10px;
  width: 100%;
  border-radius: 8px;
}

.public-DraftEditor-content figure {
  margin: 14px 0;
}

.editorWrapper .draftJsMentionPlugin__iframe__stjRT .vp-center{
  height: 500px !important;
}

.contentWrapper .draftJsMentionPlugin__iframeContainer__21EVZ {
  width: 100%;
  height: 0;
  position: relative;
  padding-bottom: 56.25%
}

.contentWrapper .draftJsMentionPlugin__iframe__stjRT {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.editorWrapper .draftJsMentionPlugin__iframeContainer__21EVZ {
  width: 100%;
  height: 0;
  position: relative;
  padding-bottom: 56.25%
}

.editorWrapper .draftJsMentionPlugin__iframe__stjRT {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.draftJsMentionPlugin__mentionSuggestions__2DWjA {
  border-radius: 8px;
  box-shadow: 0px 0px 6px #00000033;
  width: 500px;
  padding: 8px 0;
}

.draftJsEmojiPlugin__image__192TI {
  width: 100%;
}

.editor {
  box-sizing: border-box;
  border: 1px solid #ddd;
  cursor: text;
  padding: 16px;
  border-radius: 2px;
  margin-bottom: 2em;
  box-shadow: inset 0px 1px 8px -3px #ABABAB;
  background: #fefefe;
}

.editor :global(.public-DraftEditor-content) {
  min-height: 140px;
}

.options {
  margin-bottom: 20px;
}
